import { Box, HStack, Tooltip, useRadio, useRadioGroup, UseRadioProps } from '@chakra-ui/react';
import { CheckCircleIcon, EditIcon, LockIcon, ViewOffIcon } from '@chakra-ui/icons';
import { FC, ReactNode } from 'react';

import { OptionOfferState, useOffers, ValueOfferState } from '@/contexts/OfferContext';

interface IRadioCardProps extends UseRadioProps {
  children: ReactNode;
  isActive?: boolean;
}
const RadioCard: FC<IRadioCardProps> = ({ children, isActive, ...rest }) => {
  const { getInputProps, getCheckboxProps } = useRadio(rest);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth={0}
        borderRadius={3}
        sx={
          isActive
            ? {
                bg: 'brand.500',
                color: 'white',
              }
            : {}
        }
        px={2}
        py={1}
        pt={0}
      >
        {children}
      </Box>
    </Box>
  );
};

const valueOptions = [
  {
    label: 'Hidden',
    icon: <ViewOffIcon />,
    value: ValueOfferState.Hidden,
  },
  {
    label: 'Read-only',
    icon: <LockIcon />,
    value: ValueOfferState.ReadOnly,
  },
  {
    label: 'Editable',
    icon: <EditIcon />,
    value: ValueOfferState.Editable,
  },
] as const;

const optionOptions = [
  {
    label: 'Hidden',
    icon: <ViewOffIcon />,
    value: OptionOfferState.Hidden,
  },
  {
    label: 'Read-only',
    icon: <LockIcon />,
    value: OptionOfferState.ReadOnly,
  },
  {
    label: 'Read-only value, editable checkbox',
    icon: <CheckCircleIcon />,
    value: OptionOfferState.ReadOnlyValue,
  },
  {
    label: 'Editable',
    icon: <EditIcon />,
    value: OptionOfferState.Editable,
  },
] as const;

interface IOfferToggleProps {
  name: string;
  isOption?: boolean;
}

export const OfferToggle: FC<IOfferToggleProps> = ({ isOption, name }) => {
  const { offerState, setOfferState, isInternal } = useOffers();
  const { getRadioProps, getRootProps } = useRadioGroup({
    name: 'offerValue',
    value: (offerState[name] || OptionOfferState.Hidden).toString(),
    onChange: (val) => setOfferState(name, parseInt(val, 10)),
  });

  if (!isInternal) {
    return null;
  }

  const state = offerState[name] || OptionOfferState.Hidden;
  const options = isOption ? optionOptions : valueOptions;

  return (
    <HStack {...getRootProps()}>
      {options.map((option) => (
        <RadioCard isActive={option.value === state} key={option.label} {...getRadioProps({ value: option.value })}>
          <Tooltip label={option.label} placement="top">
            {option.icon}
          </Tooltip>
        </RadioCard>
      ))}
    </HStack>
  );
};
