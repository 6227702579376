import { OptionOfferState } from '../../contexts/OfferContext';
import { IInputData } from '../../interfaces/IInputData';
import { IPayload } from '../../interfaces/IPayload';
import { IPropertyOrders } from '../../interfaces/IPropertyOrders';

export function mapObjectsToArrays<TItem>(
  propertyOrders: Array<string> | ReadonlyArray<string> = [],
  data: Record<string, TItem>,
): Array<TItem> {
  return propertyOrders.map((key) => data[key]).filter((item) => item !== undefined);
}

function mapArraysToObjects<TItem>(
  propertyOrders: Array<string> = [],
  values: Array<TItem>,
  defs?: Record<string, TItem>,
): Record<string, TItem> {
  const def = 0 as TItem;

  return propertyOrders.reduce(
    (acc: Record<string, TItem>, key, i) => ({
      ...acc,
      ...(defs && defs[key] != undefined && { [key]: values[i] ?? defs[key] ?? def }),
      ...(!defs ? { [key]: values[i] ?? def } : {}),
    }),
    {},
  );
}

export function inputToPayload(
  propertyOrders: IPropertyOrders,
  input: IInputData,
  offerFields: ReadonlyArray<string>,
  offerState: Record<string, OptionOfferState> = {},
): IPayload {
  const result: IPayload = {
    flags: mapObjectsToArrays(propertyOrders.flags, input.flags ?? {}),
    values: mapObjectsToArrays(propertyOrders.values, input.values ?? {}),
    options: mapObjectsToArrays(propertyOrders.options, input.options ?? {}),
    offer: mapObjectsToArrays(offerFields, offerState),
  };

  return result;
}

export function payloadToInput(
  propertyOrders: IPropertyOrders,
  payload: IPayload,
  defaults: IInputData,
  offerFields: ReadonlyArray<string> = [],
): IInputData {
  const result: IInputData = {
    flags: mapArraysToObjects(propertyOrders.flags, payload.flags ?? [], defaults.flags),
    values: mapArraysToObjects(propertyOrders.values, payload.values ?? []),
    options: mapArraysToObjects(propertyOrders.options, payload.options ?? [], defaults.options),
    offer: mapArraysToObjects(offerFields.slice(), payload.offer ?? [], defaults.offer),
  };

  return result;
}

export function toInt(value: number): number {
  return Math.round(Math.abs(value) * 100);
}

export function toFloat(value: number): number {
  return value / 100;
}

export function compressEnum(bitSize: number, ...values: Array<number>): number {
  const offset = 2 ** bitSize;

  return values?.reduce((acc, val) => acc * offset + val) ?? 0;
}

export function decompressEnum(bitSize: number, value: number, count: number): Array<number> {
  const offset = 2 ** bitSize;
  const result = [];
  let curr = value;

  for (let i = 0; i < count; i++) {
    result.push(curr % offset);
    curr = Math.floor(curr / offset);
  }

  return result.reverse();
}

export function compressFlags(...flags: Array<boolean>): number {
  return flags?.length ? parseInt(flags.map(Number).join(''), 2) : 0;
}

export function decompressFlags(flags: number, count: number): Array<boolean> {
  return flags
    .toString(2)
    .padStart(count, '0')
    .split('')
    .map((val) => val === '1');
}
