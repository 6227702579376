import { Box, chakra, Container, HStack, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { convertCurrency } from '@/utils/convert-currency';
import { Eur } from '@/constants/currencies';
import { useCurrencies } from '@/contexts/CurrencyContext';

const SummaryContainer = chakra(Box, {
  baseStyle: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    color: 'floatingText.500',
    backgroundColor: 'floatingBg.300',
    transition: 'bottom .5s ease-in-out',
    zIndex: 2,
  },
});

export const Summary = ({ label, value }: { label: string; value: number }) => {
  const { t } = useTranslation();
  const { currency: primary, secondary, local } = useCurrencies();
  const currency = primary || Eur;

  return (
    <SummaryContainer>
      <Container maxW={840} px={[4, 2, 1]} py={5}>
        <HStack w="100%" alignItems="flex-start">
          <Box flex={1} fontSize={['md', 'lg']}>
            {t(label)}
          </Box>
          <Stack direction={['column', 'row']} spacing={0}>
            <Box w={['auto', 180]} textAlign="right" fontWeight={700} fontSize="lg">
              {currency.format(convertCurrency(value, local, currency))}
            </Box>
            {secondary && secondary !== currency && (
              <Box w={['auto', 180]} textAlign="right" opacity={0.8} fontSize={['sm', 'lg']}>
                {secondary.format(convertCurrency(value, local, secondary))}
              </Box>
            )}
          </Stack>
        </HStack>
      </Container>
    </SummaryContainer>
  );
};
