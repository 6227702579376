import { currencies, ICurrency } from '@/constants/currencies';
import { usePreferences } from '@/contexts/PreferencesContext';
import { Currency } from '@/enums/Currency';

function getCurrency(local: ICurrency, selected?: Currency, defaultCurrency?: ICurrency): ICurrency | undefined {
  if (!selected || selected === Currency.Default) {
    return defaultCurrency;
  }

  if (selected === Currency.None) {
    return undefined;
  }

  if (selected === Currency.Local) {
    return local;
  }

  return currencies[selected];
}

export function useCurrency(
  local: ICurrency,
  primary: ICurrency,
  secondary?: ICurrency,
): {
  currency: ICurrency;
  secondary?: ICurrency;
  local: ICurrency;
} {
  const {
    preferences: { currency: selectedPrimaryCurrency, secondary: selectedSecondaryCurrency },
  } = usePreferences();

  const primaryCurrency = getCurrency(local, selectedPrimaryCurrency, primary);

  if (!primaryCurrency) {
    throw new Error('Primary currency is undefined!'); // TODO: check the business logic for this
  }

  const secondaryCurrency = getCurrency(local, selectedSecondaryCurrency, secondary);

  return {
    local,
    currency: primaryCurrency,
    secondary: secondaryCurrency,
  };
}
