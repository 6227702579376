import { FormattedNumberInput, IFormattedNumberInputProps } from '@/components/shared/fields/FormattedNumberInput';
import { chakra, useColorModeValue } from '@chakra-ui/react';
import { useMemo } from 'react';
import { currencies } from '../../constants/currencies';

const NumberField = chakra(FormattedNumberInput, {
  baseStyle: {
    border: 0,
    textAlign: 'right',
    fontSize: 'xl',
    fontWeight: 'bold',
    maxW: 200,
    h: '44px',
    pr: 2,
  },
});

interface IMoneyInputProps extends Omit<IFormattedNumberInputProps, 'parse' | 'stringify'> {
  isDisabled?: boolean;
  currency: string;
}

export const MoneyInput = ({ isDisabled, currency, ...props }: IMoneyInputProps) => {
  const stringify = useMemo(() => {
    const currencyObj = Object.values(currencies).find((c) => c.key === currency);

    return currencyObj?.format || ((v: number) => `${v.toFixed(2)} ${currency}`);
  }, [currency]);
  const bg = useColorModeValue('gray.200', 'gray.600');

  return (
    <NumberField
      stringify={stringify}
      disabled={isDisabled}
      bg={bg}
      color={useColorModeValue('black', 'white')}
      allowMouseWheel
      {...props}
    />
  );
};
