import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import { convertCurrency } from '@/utils/convert-currency';
import { ICurrency } from '@/constants/currencies';
import { useCurrencies } from '@/contexts/CurrencyContext';
import { Chart, ChartData, IChartState } from './Chart';
import { usePreferences } from '../../contexts/PreferencesContext';

const conversion = (first: ICurrency, second: ICurrency): string => {
  const value = convertCurrency(1, first, second);

  if (value > 1) {
    return `${first.format(1)} = ${second.format(value)}`;
  }

  return `${second.format(1)} = ${first.format(1 / value)}`;
};

export const Footer = <TState extends IChartState>({
  label,
  isOffer,
  state,
  chart,
}: {
  label: string;
  isOffer?: boolean;
  state?: TState;
  chart?: ChartData<TState>;
}) => {
  const { t } = useTranslation();
  const { currency: primary, secondary, local } = useCurrencies();
  const {
    preferences: { chart: chartEnabled },
  } = usePreferences();

  return (
    <Box mt="-24px" pb={142} lineHeight="2xl">
      {isOffer && state && chart && chartEnabled && (
        <Box mb={8}>
          <Chart chart={chart} state={state} />
        </Box>
      )}
      {state && (primary !== local || (secondary && secondary !== primary && secondary !== local)) && (
        <Box mb={2}>
          {primary !== local && <Box>{conversion(primary, local)}</Box>}
          {primary !== secondary && secondary && secondary !== local && <Box>{conversion(primary, secondary)}</Box>}
        </Box>
      )}
      <Box dangerouslySetInnerHTML={{ __html: t(label) }} />
    </Box>
  );
};
