import { Text, Box, useColorModeValue, Grid } from '@chakra-ui/react';

import { MoneyInput } from '@/components/shared/MoneyInput';
import { EntryRow } from '@/components/shared/EntryRow';
import { Eur } from '@/constants/currencies';
import { convertCurrency } from '@/utils/convert-currency';
import { useCurrencies } from '@/contexts/CurrencyContext';
import { useOffers } from '@/contexts/OfferContext';
import Link from 'next/link';

export const AmountField = ({
  title,
  subtitle,
  highlighted,
  hasAccent,
  value,
  comparisonValue,
  onChange,
  description,
  name,
  min,
  isInvalid,
  isPlainNumber,
  isPercentage,
  hint,
  href,
  valueHref,
}: {
  title: string;
  subtitle?: string;
  highlighted?: boolean;
  hasAccent?: boolean;
  value: number | null;
  comparisonValue?: number;
  onChange?: (valueAsNumber: number) => void;
  description?: string;
  name?: string;
  min?: number;
  isInvalid?: boolean;
  isPlainNumber?: boolean;
  isPercentage?: boolean;
  hint?: string;
  href?: string;
  valueHref?: string;
}) => {
  const { getFieldOfferState } = useOffers();
  const { isReadOnly } = getFieldOfferState(name);
  const { currency: primary, secondary, local } = useCurrencies();
  const currency = primary || Eur;

  const detailColor = useColorModeValue(hasAccent ? 'gray.200' : 'gray.500', hasAccent ? 'gray.800' : 'gray.600');
  const textColor = useColorModeValue(hasAccent ? 'white' : 'black', hasAccent ? 'black' : 'white');

  const showComparison = comparisonValue !== undefined && comparisonValue.toFixed(2) !== value?.toFixed(2);

  const isCurrency = !isPercentage && !isPlainNumber;

  return (
    <EntryRow
      title={title}
      subtitle={subtitle}
      highlighted={highlighted}
      description={description}
      name={name}
      hasAccent={hasAccent}
      href={href}
    >
      <Grid templateColumns={showComparison ? '1fr 1fr' : 'auto'}>
        {showComparison && (
          <Box
            alignItems="flex-end"
            display="flex"
            flexDirection="column"
            className={highlighted ? 'summary' : ''}
            opacity={0.6}
          >
            <Text variant={highlighted ? 'highlighted' : 'normal'} fontWeight={700} color={textColor}>
              {!isCurrency ? comparisonValue : currency.format(convertCurrency(comparisonValue, local, currency))}
            </Text>

            {isCurrency && secondary && secondary !== currency && (
              <Text
                color={textColor}
                variant={highlighted ? 'highlighted' : 'normal'}
                pt={1.5}
                fontSize="sm"
                lineHeight="lg"
              >
                {secondary.format(convertCurrency(comparisonValue, local, secondary))}
              </Text>
            )}
          </Box>
        )}
        {value !== null && (
          <Box alignItems="flex-end" display="flex" flexDirection="column" className={highlighted ? 'summary' : ''}>
            {onChange && !isReadOnly ? (
              <>
                {hint && (
                  <Box color="brand.500" pos="absolute" top={-5}>
                    {hint} ⇩
                  </Box>
                )}
                <MoneyInput
                  onChange={(value: number) => onChange(convertCurrency(value, currency, local))}
                  value={convertCurrency(value, local, currency)}
                  step={currency.step}
                  min={min ?? currency.min}
                  max={currency.max}
                  currency={currency.key}
                  isDisabled={isReadOnly}
                  isInvalid={isInvalid}
                />
              </>
            ) : (
              <Text
                as={valueHref ? Link : 'span'}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                href={valueHref ? valueHref : undefined}
                variant={highlighted ? 'highlighted' : 'normal'}
                fontWeight={700}
                color={valueHref ? 'brand.500' : textColor}
                _hover={valueHref ? { textDecoration: 'underline' } : undefined}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {isPlainNumber
                  ? value
                  : isPercentage
                  ? `${(value * 100).toFixed(2)}%`
                  : currency.format(convertCurrency(value, local, currency))}
              </Text>
            )}
            {isCurrency && secondary && secondary !== currency && (
              <Text
                color={detailColor}
                variant={highlighted ? 'highlighted' : 'normal'}
                pt={1.5}
                fontSize="sm"
                lineHeight="lg"
              >
                {secondary.format(convertCurrency(value, local, secondary))}
              </Text>
            )}
          </Box>
        )}
      </Grid>
    </EntryRow>
  );
};
