import { Box, useColorModeValue } from '@chakra-ui/react';
import React, { FC } from 'react';

import { useOffers } from '@/contexts/OfferContext';

interface ISegmentProps {
  fields?: Array<string>;
  children?: React.ReactNode;
  isHighlighted?: boolean;
  isSmall?: boolean;
}

export const Segment: FC<ISegmentProps> = ({ children, fields, isHighlighted, isSmall }) => {
  const { getFieldOfferState } = useOffers();
  const isHidden = (fields?.every((field) => getFieldOfferState(field).isHidden) || fields?.length === 0) ?? false;
  const bg = useColorModeValue('gray.100', 'gray.900');

  return isHidden ? null : (
    <Box
      mb={8}
      _last={{ pb: 100 }}
      bg={isHighlighted ? bg : undefined}
      borderRadius={4}
      px={isHighlighted ? 10 : 0}
      py={isSmall ? 8 : 12}
    >
      {children}
    </Box>
  );
};
