import { FC, ReactNode } from 'react';
import { createContext } from '@chakra-ui/react-utils';

import { useCurrency } from '@/hooks/useCurrency';
import { ICurrency } from '@/constants/currencies';

const [Provider, useCurrencies] = createContext<{ currency: ICurrency; secondary?: ICurrency; local: ICurrency }>({
  name: 'CurrencyContext',
  errorMessage: 'useCurrencies must be used within a CurrencyProvider',
});

interface ICurrencyProviderProps {
  local: ICurrency;
  primary: ICurrency;
  secondary?: ICurrency;
  children?: ReactNode;
}
const CurrencyProvider: FC<ICurrencyProviderProps> = ({ local, primary, secondary, children }) => {
  const currency = useCurrency(local, primary, secondary);

  return <Provider value={currency}>{children}</Provider>;
};

export { CurrencyProvider, useCurrencies };
