import { forwardRef, Input, InputProps, useBoolean, useMergeRefs } from '@chakra-ui/react';
import { useEventListener } from '@chakra-ui/react-use-event-listener';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { getStepFactor, normalizeValue } from './utils/number.utils';
import { clampValue } from './utils/selection.utils';

export interface IFormattedNumberInputProps extends Omit<InputProps, 'value' | 'onChange'> {
  stringify(value: number): string;
  value: number;
  onChange: (val: number) => void;
  step: number;
  min?: number;
  max?: number;
  allowMouseWheel?: boolean;
}

export const FormattedNumberInput = forwardRef<IFormattedNumberInputProps, 'input'>(
  ({ stringify, onChange: onParentChange, value, min, max, step, allowMouseWheel, ...rest }, ref) => {
    const [rawValue, setRawValue] = useState<string | null>(value.toFixed(2));
    const [isFocused, focused] = useBoolean(false);

    const fieldValue = useMemo(
      () => (isFocused ? rawValue : stringify(value)) ?? '',
      [value, stringify, isFocused, rawValue],
    );

    useEventListener(
      () => inputRef.current,
      'wheel',
      (event: globalThis.WheelEvent) => {
        const doc = inputRef.current?.ownerDocument ?? document;
        const isInputFocused = doc.activeElement === inputRef.current;

        if (!allowMouseWheel || !isInputFocused) return;

        event.preventDefault();

        const stepFactor = getStepFactor(event) * step;
        const direction = Math.sign(event.deltaY);
        const value = parseFloat(normalizeValue(rawValue ?? '0'));
        const newValue = value - stepFactor * direction;

        setRawValue(stringify(newValue));
        onParentChange(clampValue(newValue, { min, max }));
      },
      { passive: false },
    );

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const valueAsString = e.target.value;

        setRawValue(valueAsString);
        const rawValue = parseFloat(normalizeValue(valueAsString)) || 0;

        onParentChange(clampValue(rawValue, { min, max }));
      },
      [onParentChange, min, max],
    );

    const onFocus = useCallback(() => {
      setRawValue(fieldValue);
      focused.on();
    }, [focused, fieldValue]);

    const inputRef = useRef<HTMLInputElement>(null);

    return (
      <Input
        ref={useMergeRefs(inputRef, ref)}
        role="spinbutton"
        min={min}
        max={max}
        aria-valuenow={value}
        autoComplete="off"
        autoCorrect="off"
        value={fieldValue}
        onBlur={focused.off}
        onFocus={onFocus}
        onChange={onChange}
        {...rest}
      />
    );
  },
);
