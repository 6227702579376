import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/react';
import { useState } from 'react';

export const Info = ({ text, header }: { text?: string; header?: boolean }) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  return text ? (
    <Tooltip label={text} fontSize="md" isOpen={isLabelOpen}>
      <InfoOutlineIcon
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(true)}
        width={header ? 4 : 5}
        height={header ? 4 : 5}
        ml={header ? 2 : 0}
        mb={header ? 0.5 : 0}
        color="gray.600"
      />
    </Tooltip>
  ) : null;
};
