import { countries } from '../../constants/countries';
import { OptionOfferState } from '../../contexts/OfferContext';
import { Brand } from '../../enums/Brand';
import { Country } from '../../interfaces/Country';
import { IInputData } from '../../interfaces/IInputData';
import { IPayload } from '../../interfaces/IPayload';
import { IPropertyOrders } from '../../interfaces/IPropertyOrders';
import {
  inputToPayload,
  compressFlags,
  toInt,
  decompressFlags,
  toFloat,
  payloadToInput,
  compressEnum,
  decompressEnum,
} from './utils';
import { getNumericPositions, getSignature, encodeNumber, decodeNumber, SpecialChars, BufferMarkers } from './v4.utils';

const isDev = process.env.NODE_ENV === 'development';

const countryCodes: Record<Country, string> = {
  hr: '2',
  si: '3',
  me: '4',
  mk: '5',
  stocks: '6',
};

// Limitations:
// * Maximal encoded number is 54^5 = 459 165 024 (or 4 591 650,24 when using decimal)
// * Only 2 decimal places are supported

// Record structure
// [flags]: number
// [values]: number[]
// [separator]: BufferMarkers
// [optionsFlags]: number
// [optionsValues]: number[]
// Optional:
//   [separator]: BufferMarkers
//   [offer]: number[]
// [signature]: number

export function serializeV4<TInputData extends IInputData>(
  propertyOrders: IPropertyOrders,
  state: TInputData,
  setKey: (key: string, value: string) => void,
  brand: Brand,
  country?: Country,
  offerState?: Record<string, OptionOfferState>,
  offerFields: ReadonlyArray<string> = [],
): string {
  const payload = inputToPayload(propertyOrders, state, offerFields, offerState);
  const numeric = getNumericPositions(propertyOrders);

  const data: Array<number> = [];

  data.push(compressFlags(...(payload.flags ?? [])));
  payload.values?.forEach((value, index) => data.push(numeric.includes(index) ? value : toInt(value)));

  while (data[data.length - 1] === 0) {
    data.pop();
  }

  if (payload.options?.length || payload.offer?.length) {
    data.push(BufferMarkers.Separator);
    const flags = payload.options?.map((option) => option.flag) ?? [];

    while (flags.length < (propertyOrders.options?.length ?? 0)) {
      flags.push(false);
    }
    data.push(compressFlags(...flags));
    payload.options?.forEach((option) => {
      return data.push(toInt(option.value));
    });
  }

  if (payload.offer?.length) {
    data.push(BufferMarkers.Separator);
    data.push(compressEnum(2, ...(payload.offer as Array<number>)));
    // payload.offer?.forEach((value) => data.push(value));
  }
  data.push(getSignature(data, brand));

  if (isDev) {
    console.log(
      'serializeV4',
      data.map((d) => (d === BufferMarkers.Separator ? '|' : d)),
    );
  }

  const arr = data.map((val) => encodeNumber(val || 0));
  const compressed = arr.join('').replace(/A{3,9}/g, (match) => `${SpecialChars.ZeroShortener}${match.length}`);

  if (isDev) {
    console.log('serializeV4', data, arr);
  }

  return 'f' + (payload.offer?.length ? countryCodes[country ?? 'hr'] : '') + compressed;
}

export function parseV4(
  propertyOrders: IPropertyOrders,
  data: string,
  brand: Brand,
  defs: IInputData,
  offerFields?: ReadonlyArray<string>,
): IInputData {
  const arr: Array<number> = [];
  const useOffset = offerFields?.length ? 1 : 0;
  // SpecialChars.ZeroShortener is used to shorten the number of zeros in a row
  const dataStr = data.slice(useOffset).replace(/a\d/g, (match) => 'A'.repeat(Number(match.slice(1))));

  for (let i = 0; i < dataStr.length; i++) {
    const char = dataStr[i];

    if (char === SpecialChars.ZeroShortener) {
      const count = Number(dataStr[i + 1]);
      const sectionData = '0'.repeat(count).split('').map(Number);

      arr.push(...sectionData);
      i += 1;
    } else if (char === SpecialChars.Separator) {
      arr.push(BufferMarkers.Separator);
    } else {
      const [num, offset] = decodeNumber(dataStr.slice(i));

      arr.push(num);
      i += offset;
    }
  }

  const signature = arr.pop();
  const expectedSignature = getSignature(Array.from(arr), brand);

  if (signature !== expectedSignature) {
    // throw new Error('Invalid signature');
  }

  const result: IPayload = {
    flags: [],
    values: [],
    options: [],
    offer: [],
  };

  result.flags = decompressFlags(arr[0], propertyOrders.flags?.length ?? 0);

  let index = 1;
  let value = arr[index];
  const numeric = getNumericPositions(propertyOrders);

  while (value !== BufferMarkers.Separator && arr.length > index) {
    result.values?.push(numeric.includes(index - 1) ? value : toFloat(value));
    value = arr[++index];
  }

  value = arr[++index];
  const enabledOptions = decompressFlags(value ?? 0, propertyOrders.options?.length ?? 0);

  while (value !== BufferMarkers.Separator && arr.length > index) {
    result.options?.push({ flag: enabledOptions.shift() ?? false, value: toFloat(Math.abs(arr[index + 1])) });
    value = arr[++index];
  }

  if (offerFields?.length && arr.length > index) {
    value = arr[++index];
    result.offer = decompressEnum(2, value, offerFields.length);
  }
  const parsed = payloadToInput(propertyOrders, result, defs, offerFields);

  if (isDev) {
    console.log(parsed.flags, parsed.values, parsed.options, parsed.offer);
  }

  return parsed;
}

export function getOfferCountry(data: string = window.location.hash.slice(1)) {
  const code = data[1]; // 0 is version, 1 is country code
  const countryCode = (Object.entries(countryCodes).find(([, value]) => value === code)?.[0] as Country) ?? 'hr';

  return countries.find((country) => country.key === countryCode);
}
