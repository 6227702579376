export function getStepFactor<Event extends KeyboardEvent | globalThis.WheelEvent>(event: Event) {
  let ratio = 1;

  if (event.metaKey || event.ctrlKey) {
    ratio = 0.1;
  }

  if (event.shiftKey) {
    ratio = 10;
  }

  return ratio;
}

export function detectSeparator(value: string) {
  const cleanValue = value.replace(/[^0-9.,]*/gi, '');
  const decimalSpot = value[value.length - 3];
  const separatorList = cleanValue.replace(/[0-9]*/g, '');

  const separatorMap = separatorList.split('').reduce((acc, sep) => {
    acc[sep] = (acc[sep] || 0) + 1;

    return acc;
  }, {} as Record<string, number>);

  // If there are more than one separators, return the one that is not used multiple times
  if (separatorMap[','] > 1) {
    return '.';
  } else if (separatorMap['.'] > 1) {
    return ',';
  }

  // If the decimal spot is a separator, return it
  if (decimalSpot === '.' || decimalSpot === ',') {
    return decimalSpot;
  }

  // If there is only one separator, but not on the right place, return the other one
  return separatorList[separatorList.length - 1] || ',';
}

export function normalizeValue(value: string, separator?: string) {
  const sep = separator || detectSeparator(value);
  const rawValue = value.replace(/[a-zA-Z\s$€]*/g, '');

  return sep === '.' ? rawValue.replace(/,/g, '') : rawValue.replace(/\./g, '').replace(/,/g, '.').replace(/−/, '-');
}
