export function clampValue(value: number, { min, max }: { min?: number; max?: number }) {
  if (!min && !max && min !== 0) return value;
  let clampedValue = value;

  if (min || min === 0) {
    clampedValue = Math.max(clampedValue, min);
  }

  if (max) {
    clampedValue = Math.min(clampedValue, max);
  }

  return clampedValue;
}
