import { Brand } from '../../enums/Brand';
import { IPropertyOrders } from '../../interfaces/IPropertyOrders';
import { NUMERIC_VALUES } from './constants';

const SIGNATURE_KEYS: Record<Brand, Array<number>> = {
  [Brand.Infinum]: [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 42],
  [Brand.PDC]: [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43],
  [Brand.Productive]: [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 44],
};

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZefghijklmnopqrstuvwxyz016789'; // Unused: !"\'()*-._~
const charsCount = chars.length;

export enum BufferMarkers {
  NotFound = -1, // Should not be used, but is here to document the behavior (e.g. when nothing was found in indexOf)
  Separator = -2,
  EnabledZero = 2 ** 31 - 1,
}

export enum SpecialChars {
  ZeroShortener = 'a',
  Minus = 'b',
  Separator = 'c',
  ZeroZero = 'd',
}

export function getSignature(data: Array<number>, brand: Brand): number {
  const key = SIGNATURE_KEYS[brand];

  return data.reduce((acc, curr, index) => {
    return acc + (curr % key[index % key.length]);
  }, 0);
}

export function encodeNumber(x: number): string {
  const str = encodeInt(Math.abs(x));

  if (x === BufferMarkers.Separator) {
    return SpecialChars.Separator;
  } else if (x < 0) {
    return `${str.length === 1 ? '' : str.length + 1}${SpecialChars.Minus}${str}`;
  }

  if ((str.length === 2 && str.startsWith('d')) || str.length === 1) {
    return str;
  }

  return `${str.length}${str}`;
}

function encodeInt(num: number): string {
  let code = '';
  let inNum = num;
  const zeroZero = inNum > 0 && inNum % 100 === 0;

  if (zeroZero) {
    inNum /= 100;
  }
  do {
    code = chars[inNum % charsCount] + code;
    inNum = Math.floor(inNum / charsCount);
  } while (inNum > 0);

  if (zeroZero) {
    code = SpecialChars.ZeroZero + code;
  }

  return code;
}

export function decodeNumber(data: string): [number, number] {
  const char = data[0];

  if (char === SpecialChars.ZeroZero) {
    return [decodeInt(data.slice(0, 2)), 1];
  } else if (['2', '3', '4', '5'].includes(char)) {
    const length = Number(char);

    if (data[1] === SpecialChars.Minus) {
      return [-decodeInt(data.slice(2, length + 1)), length];
    } else {
      return [decodeInt(data.slice(1, length + 1)), length];
    }
  } else if (char === SpecialChars.Minus) {
    return [-decodeInt(data.slice(1, 2)), 1];
  } else {
    return [decodeInt(char), 0];
  }
}

export function decodeInt(code: string): number {
  let num = 0;
  const zeroZero = code.startsWith(SpecialChars.ZeroZero) ? 1 : 0;

  for (let i = zeroZero; i < code.length; i++) {
    num = num * charsCount + chars.indexOf(code[i]);
  }

  if (zeroZero) {
    num *= 100;
  }

  return num;
}

export function getNumericPositions(propertyOrders: IPropertyOrders): Array<number> {
  return NUMERIC_VALUES.map((item) => propertyOrders.values.indexOf(item)).filter((x) => x >= 0);
}
