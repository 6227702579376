import { createContext } from '@chakra-ui/react-utils';

export enum ValueOfferState {
  Hidden = 0,
  ReadOnly,
  Editable,
}

enum _OptionOfferState {
  ReadOnlyValue = 3,
}

export type OptionOfferState = ValueOfferState | _OptionOfferState;

// TODO: This should probably be fixed in the future
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OptionOfferState = { ...ValueOfferState, ..._OptionOfferState };

export interface IOfferValue {
  offerState: Record<string, OptionOfferState>;
  setOfferState(field: string, value: OptionOfferState): void;
  getFieldOfferState(key: string | undefined): {
    isInternalHidden: boolean;
    isHidden: boolean;
    isReadOnly: boolean;
    isEditable: boolean;
    isReadOnlyValue: boolean;
  };
  isInternal?: boolean;
}

const [OfferProvider, useOffers] = createContext<IOfferValue>({
  name: 'OfferContext',
  errorMessage: 'useOffers must be used within a OfferProvider',
});

const BLANK_OFFER: IOfferValue = {
  offerState: {},
  setOfferState: () => {
    /**/
  },
  getFieldOfferState: () => ({
    isInternalHidden: false,
    isHidden: false,
    isReadOnly: false,
    isEditable: false,
    isReadOnlyValue: false,
  }),
};

export { OfferProvider, useOffers, BLANK_OFFER };
