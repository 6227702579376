import { AspectRatio, Box, Show } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { lazy, Suspense, useMemo } from 'react';

const Sankey = lazy(() => import('@/components/shared/Sankey').then((module) => ({ default: module.Sankey })));

import { convertCurrency } from '@/utils/convert-currency';
import { usePreferences } from '@/contexts/PreferencesContext';
import { useCurrencies } from '@/contexts/CurrencyContext';
import { isNotNull } from '@/utils/is-not-null';
import { descCompareFn } from '@/utils/desc-compare-fn';
import { ILinkOptions } from './Sankey/utils/sankey.utils';

export interface IChartState {
  flags: Record<string, unknown>;
  values: Record<string, number> & Record<string, unknown>;
}

type ChartDataFn<TState extends IChartState> = (state: TState) => Array<[string, string, unknown]>;

// each element in the tuple represents chart data for that ChartDetailLevel
export type ChartData<TState extends IChartState> =
  | [ChartDataFn<TState>]
  | [ChartDataFn<TState>, ChartDataFn<TState>]
  | [ChartDataFn<TState>, ChartDataFn<TState> | null, ChartDataFn<TState>];

export const Chart = <TState extends IChartState>({ chart, state }: { chart: ChartData<TState>; state: TState }) => {
  const { t, i18n } = useTranslation();
  const { preferences } = usePreferences();
  const { currency: primary, local } = useCurrencies();

  const data: Array<[string, string, number]> = useMemo(() => {
    i18n.language; // Make sure i18n.language is in the dependency list
    const detailsLevel =
      chart
        .map((v, i) => (v ? i : null))
        .filter(isNotNull)
        .sort(descCompareFn)
        .find((x) => x <= preferences.details) || 0;

    const chartSetupFn = chart[detailsLevel];

    if (!chartSetupFn) {
      throw new Error('Chart setup function is not defined for this level'); // TODO:
    }

    return chartSetupFn(state)
      .slice(1)
      .map(([from, to, value]) => [t(from), t(to), convertCurrency(Number(value), local, primary)]);
  }, [chart, state, preferences.details, t, local, primary, i18n.language]);

  const nodes = useMemo(() => {
    return Array.from(new Set(data.map(([from, to, value]) => (value ? [from, to] : [])).flat()));
  }, [data]);

  const links: Array<ILinkOptions> = useMemo(() => {
    return data
      .filter(([_s, _t, value]) => value)
      .map(([source, target, value]) => ({
        source,
        target,
        value,
        formattedValue: primary.format(value),
        key: `${source}/${target}`,
      }));
  }, [data, primary]);

  return (
    <Show above="md">
      <Box pb={6} role="presentation">
        <AspectRatio ratio={1280 / 400}>
          <Suspense fallback={<Box>Loading...</Box>}>
            <Sankey nodes={nodes} links={links} formatter={primary.format.bind(primary)} />
          </Suspense>
        </AspectRatio>
      </Box>
    </Show>
  );
};
